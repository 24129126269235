<template>
  <div class="layout">
    <TheHeader />
    <div class="content">
      <div class="view">
        <router-view :key="$route.path"></router-view>
      </div>
      <div class="sidebar">
        <Sidebar />
      </div>
    </div>
    <div class="related-posts" v-if="isPost">
      <RelatedPosts />
    </div>
    <TheFooter class="footer" />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import Sidebar from "@/components/Sidebar";
import RelatedPosts from "@/components/RelatedPosts";

export default {
  name: "Layout",
  components: {
    TheHeader,
    TheFooter,
    Sidebar,
    RelatedPosts,
  },
  computed: {
    isPost() {
      return this.$route.path.includes("/posts");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbfbfb;

  .content {
    max-width: 1920px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem;
  }

  .footer {
    margin-top: auto;
  }
}

@media screen and (min-width: 840px) {
  .layout {
    .content {
      grid-template-columns: 1.5fr 1fr;
    }
  }
}

@media screen and (min-width: 1290px) {
  .layout {
    .content {
      grid-template-columns: 2.5fr 1fr;
    }

    .posts-preview {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (min-width: 1640px) {
  .layout {
    .content {
      display: grid;
      grid-template-columns: 2.5fr 1fr;
    }
  }
}
</style>