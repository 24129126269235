<template>
  <router-link class="popular-post" :to="link">
    <img
      class="image"
      :src="image"
      :alt="title"
    />
    <p class="name">{{ title }}</p>
  </router-link>
</template>

<script>
export default {
  name: "PostPopular",
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-post {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0 .5rem;
  margin: 5px 0;
  border-bottom: 1px solid #f5f5f5;

  .image {
    max-width: 80px;
  }

  .name {
    font-size: var(--text-16);
    line-height: 150%;
    display: flex;
    align-items: center;
    &:hover {
      color: var(--gray-100);
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

@media screen and (min-width: 1290px) {
  .popular-post {
    .name {
      font-size: var(--text-18);
    }
  }
}
</style>
