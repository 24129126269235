<template>
  <div class="app-header">
    <h1 class="page-title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1290px) {
  .app-header {
    margin-bottom: 4rem;
    .page-title {
      font-size: var(--text-40);
      text-transform: capitalize;
    }
  }
}
</style>