<template>
  <v-app>
    <Layout />
  </v-app>
</template>

<script>
import Layout from "./views/BasePages/Layout.vue";
export default {
  name: "App",
  components: { Layout },
  data: () => ({
    //
  }),
};
</script>
