<template>
  <router-link class="post-category" :to="link">
    <p class="name">{{ category }}</p>
    <span class="quantity">{{ quantity }}</span>
  </router-link>
</template>

<script>
export default {
  name: "PostCategory",
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: String,
      default: null,
    },
    quantity: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.post-category {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin: 5px 0;

  &:last-child {
    border-bottom: none;
  }
  .name {
    color: var(--gray-80);
    font-size: var(--text-18);

    &:hover {
      color: var(--gray-100);
    }
  }

  .quantity {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: var(--blue-50);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
