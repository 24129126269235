import { httpClient } from "./index";

export const getPosts = ({ language }) => httpClient.get(`/${language}/posts/4`);

export const getPost = ({ language, postSlug }) =>
  httpClient.get(`/${language}/${postSlug}`);

export const searchPost = ({ language, value }) =>
  httpClient.get(`${language}/search/${value}`);

export const filterByCategory = ({ language, category }) =>
  httpClient.get(`${language}/category/${category}`);
