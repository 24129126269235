<template>
  <div class="blog">
    <div class="blog-content">
      <AppHeader :title="$t('layout.static.latestPosts')" />
      <div class="posts-preview" v-if="posts">
        <PostPreview
          v-for="(post, index) in posts"
          :key="index"
          :link="{ name: 'post', params: { id: post.slug } }"
          :image="post.image"
          :date="formatDate(post.created_at)"
          :category="post.category"
          :title="post.title"
          :description="(post.body).replace(/<[^>]*>?/gm, '').substring(0, 140) + '...'"
        />
      </div>
      <p v-else>{{$t("layout.static.noPosts")}}</p>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/elements/AppHeader";
import PostPreview from "@/components/PostPreview";

import { mapState, mapActions } from "vuex";
import formatDate from "@/utils/formatDate";
export default {
  name: "Blog",
  mixins: [formatDate],
  components: {
    AppHeader,
    PostPreview,
  },
  data() {
    return {};
  },
  watch: {
    language(value) {
      this.getPosts({ language: value });
    },
  },
  computed: {
    ...mapState("Blog", {
      posts: (state) => state.posts,
      language: (state) => state.language,
    }),
  },
  methods: {
    ...mapActions("Blog", ["getPosts"]),
  },
  created() {
    this.getPosts({ language: this.language });
  },
};
</script>

<style lang="scss" scoped>
.blog {
  .blog-content {
    background: #fff;
    padding: 1rem 2rem;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .blog-header {
      margin-bottom: 1rem;
    }
  }

  .posts-preview {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
  }
}

@media screen and (min-width: 1290px) {
  .blog {
    .blog-content {
      .blog-header {
        .page-title {
          font-size: var(--text-40);
          margin-bottom: 4rem;
        }
      }
    }

    .posts-preview {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>