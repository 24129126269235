<template>
  <div class="related-posts" v-if="relatedPosts.length">
    <h2 class="section-title">{{ $t("layout.static.relatedPosts") }}</h2>
    <div class="posts">
      <PostPreview
        v-for="(post, index) in relatedPosts"
        :key="index"
        :link="{ name: 'post', params: { id: post.slug } }"
        :image="post.image"
        :date="formatDate(post.created_at)"
        :category="post.category"
        :title="post.title"
        :description="
          post.body.replace(/<[^>]*>?/gm, '').substring(0, 140) + '...'
        "
      />
    </div>
  </div>
</template>

<script>
import PostPreview from "./PostPreview.vue";

import { mapState, mapActions } from "vuex";
import formatDate from "@/utils/formatDate";

export default {
  name: "RelatedPosts",
  mixins: [formatDate],
  components: { PostPreview },
  data: () => {
    return {};
  },
  watch: {
    language(value) {
      this.getPost({ language: value, postSlug: this.$route.params.id });
    },
  },
  computed: {
    ...mapState("Blog", {
      relatedPosts: (state) => state.relatedPosts,
      language: (state) => state.language,
    }),
  },
  methods: {
    ...mapActions("Blog", ["getPost"]),
  },
  created() {
    this.getPost({ language: this.language, postSlug: this.$route.params.id });
  },
};
</script>

<style lang="scss" scoped>
.related-posts {
  max-width: 1840px;
  text-align: center;
  display: grid;
  gap: 2rem;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  margin-bottom: 0;
  border-radius: 10px;

  .section-title {
    font-size: var(--text-28);
  }
}

.posts {
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-items: center;
  text-align: left;
  gap: 4rem;
}

@media screen and (min-width: 768px) {
  .related-posts {
    .posts {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 1200px) {
  .related-posts {
    .section-title {
      font-size: var(--text-40);
    }
    .posts {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>