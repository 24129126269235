<template>
  <div class="post-search">
    <form class="form" @submit.prevent>
      <input
        class="field"
        type="text"
        :placeholder="$t('layout.static.searchPlaceholder')"
        spellcheck="false"
        :value="value"
        @input="updateValue($event.target.value)"
      />
      <!-- <button class="action">
        <img
          class="icon"
          :src="require('../assets/images/icons/search.svg')"
          alt="search"
        />
      </button> -->
    </form>
  </div>
</template>

<script>
export default {
  name: "PostSearch",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.post-search {
  .form {
    display: flex;

    .field {
      width: 100%;
      height: 60px;
      border: 1px solid #eff1f5;
      margin-right: 15px;
      padding-left: 20px;
      color: var(--gray-80);
      border-radius: 4px;
      outline: none;
      transition: 0.2s;

      &:focus,
      &:hover {
        border: 1px solid var(--blue-50);
      }
    }

    .action {
      width: fit-content;
      height: 60px;
      padding: 0 1.4rem;
      border-radius: 5px;
      background: var(--blue-50);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--blue-100);
      }

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .search {
    .form {
      .field {
        font-size: var(--text-14);
      }
    }
  }
}
</style>
