import Vue from "vue";
import VueRouter from "vue-router";
import Blog from "../views/Blog.vue";
import Post from "../views/Post.vue";
import SearchResults from "../views/SearchResults.vue";
import Category from "../views/Category.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Blog,
  },
  {
    name: "post",
    path: "/posts/:id",
    component: Post,
  },
  {
    name: "search-results",
    path: "/search-results",
    component: SearchResults,
  },
  {
    name: "category",
    path: "/category/:category",
    component: Category,
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
