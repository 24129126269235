<template>
  <div class="post-wrapper">
    <div class="post-content">
      <div class="post" v-if="post">
        <h1 class="post-title">{{ post.title }}</h1>
        <p class="date">{{ formatDate(post.created_at) }}</p>
        <p class="category">{{ post.category }}</p>
        <img class="img" :src="post.image" :alt="post.title" />
        <p class="text-content" v-html="post.body"></p>
      </div>
      <div class="no-post" v-else>
        <NotFound />
      </div>
    </div>
  </div>
</template>

<script>
import NotFound from "@/views/NotFound";

import { mapState, mapActions } from "vuex";
import formatDate from "@/utils/formatDate";

export default {
  name: "Post",
  components: {
    NotFound,
  },
  mixins: [formatDate],
  data: () => {
    return {
      search: "",
    };
  },
  watch: {
    language(value) {
      this.getPost({ language: value, postSlug: this.$route.params.id });
    },
  },
  computed: {
    ...mapState("Blog", {
      post: (state) => state.post,
      language: (state) => state.language,
    }),
  },
  methods: {
    ...mapActions("Blog", ["getPost"]),
  },
  created() {
    this.getPost({ language: this.language, postSlug: this.$route.params.id });
  },
};
</script>

<style lang="scss" scoped>
.post-wrapper {
  .post-content {
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    margin-bottom: 0;
    border-radius: 10px;

    .post {
      width: 100%;

      .post-title {
        font-size: var(--text-24);
        line-height: 150%;
      }

      .date {
        color: var(--gray-10);
        margin-bottom: 1rem;
      }

      .category {
        font-weight: var(--text-semibold);
        color: var(--gray-80);
        margin-bottom: 1rem;
      }

      .img {
        width: 100%;
      }

      .text-content {
        padding-top: 3rem;
        padding-bottom: 1rem;
        color: #525f81;
        font-size: var(--text-16);
        line-height: 160%;
      }
    }
  }
}

@media screen and (min-width: 840px) {
  .post-wrapper {
    .post-content {
      .post {
        .post-title {
          font-size: var(--text-28);
        }
      }
    }
  }
}

@media screen and (min-width: 1290px) {
  .post-wrapper {
    .post-content {
      .post {
        .post-title {
          font-size: var(--text-40);
          margin-bottom: 1rem;
        }

        .text-content {
          font-size: var(--text-18);
        }
      }
    }
  }
}
</style>