<template>
  <div class="sidebar">
    <div class="posts-search">
      <SectionTitle :title="$t('layout.static.search')" />
      <PostSearch v-model="search" />
    </div>

    <div class="posts-popular">
      <SectionTitle :title="$t('layout.static.popularPosts')" />
      <PostPopular
        v-for="(post, index) in posts"
        :key="index"
        :link="{ name: 'post', params: { id: post.slug } }"
        :title="post.title"
        :image="post.image"
      />
    </div>

    <div class="posts-categories">
      <SectionTitle :title="$t('layout.static.categories')" />
      <PostCategory
        v-for="(category, index) in countCategory"
        :key="index"
        :link="{
          name: 'category',
          params: { category: category.category_slug },
        }"
        :category="category.category"
        :quantity="category.count"
      />
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import PostSearch from "@/components/PostSearch";
import PostPopular from "@/components/PostPopular";
import PostCategory from "@/components/PostCategory";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "Sidebar",
  components: {
    SectionTitle,
    PostSearch,
    PostPopular,
    PostCategory,
  },
  data() {
    return {
      search: "",
    };
  },
  watch: {
    language(value) {
      this.getPosts({ language: value });
    },
    search(value) {
      if (value) {
        this.getSearch({ language: this.language, value });
        this.setSearch({ search: value });

        if (this.$route.name !== "search-results") {
          this.$router.push({ name: "search-results" });
        }
      } else {
        this.$router.back();
      }
    },
  },
  computed: {
    ...mapState("Blog", {
      posts: (state) => state.posts,
      countCategory: (state) => state.countCategory,
      language: (state) => state.language,
    }),
  },
  methods: {
    ...mapActions("Blog", ["getPosts"]),
    ...mapActions("Blog", ["getSearch"]),
    ...mapMutations("Blog", {
      setSearch: "SET_SEARCH",
    }),
  },
  created() {
    this.getPosts({ language: this.language });
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .posts-search,
  .posts-popular,
  .posts-categories {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;

    &:hover {
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>