import {
  getPosts,
  getPost,
  searchPost,
  filterByCategory,
} from "../../../api/blog.api";

export const Blog = {
  state: {
    language: "pt",
    posts: [],
    countCategory: [],
    post: {},
    relatedPosts: [],
    postsByCategory: [],
    search: "",
    searchResults: [],
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      localStorage.setItem("language", language);
      state.language = language;
    },
    SET_POSTS(state, { posts }) {
      state.posts = posts;
    },
    SET_COUNT_CATEGORY(state, { countCategory }) {
      state.countCategory = countCategory;
    },
    SET_POST(state, { post }) {
      state.post = post;
    },
    SET_RELATED_POSTS(state, { relatedPosts }) {
      state.relatedPosts = relatedPosts;
    },
    SET_POSTS_BY_CATEGORY(state, postsByCategory) {
      state.postsByCategory = postsByCategory;
    },
    SET_SEARCH(state, { search }) {
      state.search = search;
    },
    SET_SEARCH_RESULTS(state, searchResults) {
      state.searchResults = searchResults;
    },
  },
  actions: {
    async getPosts({ state, commit }) {
      try {
        const {
          data: { posts, countCategory },
        } = await getPosts({
          language: localStorage.getItem("language") || state.language,
        });
        commit("SET_POSTS", { posts });
        commit("SET_COUNT_CATEGORY", { countCategory });
      } catch (e) {
        console.log(e);
      }
    },
    async getPost({ state, commit }, { postSlug }) {
      try {
        const {
          data: { post, relatedPosts },
        } = await getPost({ language: localStorage.getItem("language") || state.language, postSlug });
        commit("SET_POST", { post });
        commit("SET_RELATED_POSTS", { relatedPosts });
      } catch (e) {
        console.log(e);
      }
    },
    async getSearch({ state, commit }, { value }) {
      try {
        const { data } = await searchPost({ language: localStorage.getItem("language") || state.language, value });
        commit("SET_SEARCH_RESULTS", data.search_results);
      } catch (e) {
        console.log(e);
      }
    },
    async getPostsByCategory({ state, commit }, {  category }) {
      try {
        const { data } = await filterByCategory({ language: localStorage.getItem("language") || state.language, category });
        commit("SET_POSTS_BY_CATEGORY", data.posts_by_category);
      } catch (e) {
        console.log(e);
      }
    },
  },
  namespaced: true,
};
