<template>
  <h2 class="section-title">{{ title }}</h2>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.section-title {
  font-size: var(--text-28);
  font-weight: var(--text-bold);
}
</style>
