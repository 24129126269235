<template>
  <div class="search-results">
    <AppHeader :title="$t('layout.static.searchResult') + ': ' + search" />
    <div class="posts-preview" v-if="searchResults.length">
      <PostPreview
        v-for="(post, index) in searchResults"
        :key="index"
        :link="{ name: 'post', params: { id: post.slug } }"
        :image="post.image"
        :date="formatDate(post.created_at)"
        :category="post.category"
        :title="post.title"
        :description="(post.body).replace(/<[^>]*>?/gm, '').substring(0, 140) + '...'"
      />
    </div>
    <p v-else>{{ $t("layout.static.noPosts") }}</p>
  </div>
</template>

<script>
import AppHeader from "@/components/elements/AppHeader";
import PostPreview from "@/components/PostPreview";

import { mapState, mapActions } from "vuex";

import formatDate from "@/utils/formatDate";

export default {
  name: "SearchResults",
  mixins: [formatDate],
  components: {
    AppHeader,
    PostPreview,
  },
  watch: {
    language(value) {
      this.getSearch({ language: value, value: this.search });
    },
  },
  computed: {
    ...mapState("Blog", {
      search: (state) => state.search,
      searchResults: (state) => state.searchResults,
      language: (state) => state.language,
    }),
  },
  methods: {
    ...mapActions("Blog", ["getSearch"]),
  },
};
</script>

<style lang="scss" scoped>
.search-results {
  background: #fff;
  padding: 1rem 2rem;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  
  .posts-preview {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
  }
}

@media screen and (min-width: 1290px) {
  .search-results {
    .posts-preview {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>