<template>
  <div class="not-found">
    <div class="icon">
      <img src="@/assets/images/icons/not_found.svg" alt="Not Found" />
    </div>
    <div class="message">
      <h1>Página não encontrada</h1>
      <p>
        Oops não conseguimos encontrar essa página. Tente ir para a
        <router-link to="/">home</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  margin-bottom: 20px;

  .icon {
    max-width: 100%;

    img {
      width: 50%;
    }
  }

  .message {
    text-align: center;
    border: 2px solid var(--blue-50);
    padding: 50px 30px;
    margin: 0 30px;
  }
}
</style>
